import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { IRouterLink } from 'core/plugins/model'
import { matchesCurrentPath } from 'core/plugins/route-helpers'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import Theme from 'core/themes/model'
import { prop } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import generateTestId from 'utils/test-helpers'
import NavMenu from '../menu/NavMenu'
import NavItem from './NavItem'

interface Props extends IRouterLink {
  isActive?: boolean
  tooltip?: boolean
  drawerOpen?: boolean
  menuOffset?: { vertical: number; horizontal: number }
  activeDisplayType?: 'background' | 'bar'
  className?: string
}

export default function NavSection({
  name,
  link,
  nestedLinks = undefined,
  icon,
  menuOffset = undefined,
  className = undefined,
  drawerOpen = false,
  isActive = false,
  tooltip = false,
}: Props) {
  const [open, setOpen] = useState(!!isActive)
  const [menuOpen, setMenuOpen] = useState(false)
  const [height, setHeight] = useState(0)
  const content = useRef(null)
  const { currentPath } = usePluginRouter()

  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { blueprintType, features } = session

  const filteredNestedLinks = nestedLinks?.filter((link) => {
    return link?.requiredFeatures ? link.requiredFeatures(features) : true
  })

  useEffect(() => {
    setHeight(drawerOpen && open ? content?.current?.scrollHeight : 0)
  }, [open, drawerOpen])

  // Open the section if the section is active
  useEffect(() => {
    if (!!isActive) setOpen(true)
  }, [isActive])

  const handleMenuOpen = () => {
    if (drawerOpen || !filteredNestedLinks?.length) return
    setMenuOpen(true)
  }

  const onNavItemClick = (event) => {
    // prevent from triggering click for nav item when the drawer is closed.
    // This is to prevent the nav item url from changing when the drawer is closed
    if (!filteredNestedLinks?.length) {
      return
    }

    if (!drawerOpen) {
      event.preventDefault()
      return
    }

    // Toggle the open state for nav item - open to close and vice versa
    setOpen((open) => !open)
  }

  const classes = useStyles({ isActive, open, height, drawerOpen })
  const navSection = (
    <div className={classes.navHeader} onClick={drawerOpen ? undefined : handleMenuOpen}>
      <NavItem
        name={name}
        link={link}
        isActive={isActive}
        drawerOpen={drawerOpen}
        // tooltip={tooltip}
        icon={icon}
        onClick={onNavItemClick}
        variant="sidenav1"
        tooltipProps={{ className: classes.navItemContainer }}
      />
      {filteredNestedLinks?.length && (
        <FontAwesomeIcon solid size="xs" className={classes.navIcon} onClick={() => setOpen(!open)}>
          chevron-down
        </FontAwesomeIcon>
      )}
    </div>
  )

  return (
    <>
      <NavMenu
        title={name}
        icon={icon}
        data-testid={generateTestId('nav-section', 'menu')}
        nestedLinks={filteredNestedLinks}
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        offset={menuOffset}
        origin="left top"
        anchor={navSection}
      />
      {filteredNestedLinks?.length && (
        <div
          ref={content}
          className={clsx(classes.nestedLinks)}
          onClick={(event) => event.stopPropagation()}
        >
          {filteredNestedLinks.map(
            (
              { link: nestedLink, name: nestedLinkName, disabledCond, tooltipFn, isHidden },
              index,
            ) =>
              isHidden && isHidden({ blueprintType }) ? null : (
                <NavItem
                  key={index}
                  name={nestedLinkName}
                  link={nestedLink}
                  activeDisplayType="bar"
                  className={classes.nestedNavItem}
                  isActive={matchesCurrentPath(currentPath, nestedLink)}
                  drawerOpen={drawerOpen}
                  tooltip={tooltipFn ? tooltipFn({ blueprintType }) : ''}
                  variant="sidenav2"
                  tooltipProps={{ className: classes.nestNavContainer }}
                  disabled={disabledCond ? disabledCond({ blueprintType }) : false}
                />
              ),
          )}
        </div>
      )}
    </>
  )
}
interface StyleProps {
  isActive: boolean
  open: boolean
  drawerOpen: boolean
  height: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  navHeader: {
    display: 'flex',
    flexDirection: 'column',

    '&:hover': {
      transition: 'background 0.2s ease',
      background: ({ isActive }) => (isActive ? 'unset' : theme.components.sidebar.border),
    },
  },
  navIcon: {
    display: ({ drawerOpen }) => (drawerOpen ? 'flex' : 'none'),
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 24,
    position: 'relative',
    left: 240,
    bottom: 25,
    color: theme.components.sidebar?.text,
    transform: ({ open }) => (open ? 'rotate(-180deg)' : 'rotate(0deg)'),
    transition: 'transform 0.6s ease, color 0.2s ease',
    '&:hover': {
      color: theme.components.sidebar.hoverText,
    },
  },
  navItemContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginLeft: 23,
    color: ({ isActive }) => theme.components.sidebar?.[isActive ? 'activeText' : 'text'],
  },
  nestNavContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    color: ({ isActive }) => theme.components.sidebar?.[isActive ? 'activeText' : 'text'],
    paddingLeft: theme.spacing(2),
  },
  nestedNavItem: {
    '&:hover': {
      transition: 'background 0.2s ease',
      background: theme.components.sidebar.border,
    },
  },

  nestedLinks: {
    overflow: 'hidden',
    transition: ' max-height 0.6s ease',
    maxHeight: ({ height }) => height,
    marginLeft: theme.spacing(4.5),
  },
}))
