import { makeStyles } from '@material-ui/styles'
import { AppPlugins, CustomerTiers, helpUrl, logoutUrl } from 'app/constants'
import { sessionActions, SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { isNilOrEmpty, isTruthy } from 'utils/fp'
import { useToast } from 'core/providers/ToastProvider'
import { RootState } from 'app/store'
import { keys, path, pathOr, prop, paths } from 'ramda'
import pluginManager from 'core/plugins/pluginManager'
import useScopedPreferences from 'core/session/useScopedPreferences'
// import ClusterUpgradeBanner from 'core/banners/ClusterUpgradeBanner'
import Theme from 'core/themes/model'
import DocumentMeta from 'core/components/DocumentMeta'
import Bugsnag from 'utils/bugsnag'
import { addZendeskWidgetScriptToDomBody, hideZendeskWidget } from 'utils/zendesk-widget'
import { isProductionEnv, isDecco } from 'core/utils/helpers'
import Watchdog from 'core/watchdog'
import sessionTimeoutCheck from 'core/watchdog/session-timeout'
import OnboardingPage, { OnboardingStepNames } from 'k8s/components/onboarding/onboarding-page'
import Progress from 'core/components/progress/Progress'
import DataKeys from 'k8s/DataKeys'
import { cacheStoreKey, updatingStoreKey, loadingStoreKey } from 'core/caching/cacheReducers'
import { PreferencesState, preferencesStoreKey } from 'core/session/preferencesReducers'
import {
  determineCurrentStack,
  isOnboardingEnv,
  loadRegionFeatures,
  needsOnboardingBackfill,
  updateGlobalFeatureFlags,
  updateOnboardingStatus,
} from 'core/containers/AuthController'
import useListAction from 'core/hooks/useListAction'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import {
  getResmgrServiceConfig,
  getBlueprints,
  getHostNetworkConfigs,
} from 'openstack/components/infrastructure/actions'

interface StyleProps {
  path?: string
  showNavBar?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.palette.grey['000'],
  },
  secondaryHeader: {
    position: 'fixed',
    top: 55,
    width: '100%',
    zIndex: 1100,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: theme.spacing(6),
  },
  contentMain: {
    padding: ({ showNavBar }) =>
      showNavBar ? theme.spacing(3, 3, 3, 3.5) : theme.spacing(3, 3, 3, 25),
  },
  sandboxBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,

    fontWeight: 'bold',
    fontSize: 16,
    '& a': {
      backgroundColor: '#F3F3F4',
      margin: theme.spacing(0, 1),
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      '& *': {
        color: theme.palette.background,
      },
      '& i': {
        marginLeft: theme.spacing(),
      },
    },
  },
  modal: {
    position: 'fixed',
    left: 0,
    top: '55px',
    width: '100vw',
    height: 'calc(100vh - 55px)', // 55px is the toolbar height
    overflow: 'auto',
    zIndex: 1500,
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(2, 4),
    boxSizing: 'border-box',
  },
}))

const allClustersLoadingPaths = paths<boolean>(
  [DataKeys.Clusters, DataKeys.ImportedClusters, DataKeys.CapiClusters].map((cacheKey) => [
    cacheStoreKey,
    loadingStoreKey,
    cacheKey,
  ]),
)

export const getUnderlayInfo = (blueprintSettings, hostConfigs) => {
  const underlayType = blueprintSettings?.virtualNetworking?.underlayType
  const vnidRange = blueprintSettings?.virtualNetworking?.vnidRange || ''
  const vniRanges = vnidRange
    .split(',')
    .map((range) => range.trim())
    .map((range) => {
      const parts = range.split(':')
      return parts[0] === parts[1] ? parts[1] : `${parts[0]}-${parts[1]}`
    })
  const firstHostConfig = hostConfigs?.[0]
  const tunnelPhysnet = firstHostConfig?.tunnelingInterface
  const labelKeys = keys(firstHostConfig?.networkLabels)
  const tunnelLabel = labelKeys?.find((key) => {
    return firstHostConfig?.networkLabels?.[key] === tunnelPhysnet
  })
  return {
    type: underlayType,
    label: tunnelLabel,
    ranges: vniRanges,
  }
}

const AuthContainer = () => {
  const { history, location } = useReactRouter()
  const { pathname } = history.location
  const dispatch = useDispatch()
  const showToast = useToast()
  const { prefs, updatePrefs } = useScopedPreferences()
  const { lastStack } = prefs
  // stack is the name of the plugin (ex. kubernetes, account, developer, theme)
  const [currentStack, setStack] = useState(determineCurrentStack(history.location, lastStack))
  const [loadingFeatureFlags, setLoadingFeatureFlags] = useState(false)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { id: userId, name, displayName, role },
    features,
    onboardingRedirectToUrl,
    activeRegion,
    loadingRegion,
  } = session
  const airgapped = features?.experimental?.airgapped || false
  const { global = {} } = useSelector(prop<string, PreferencesState>(preferencesStoreKey))
  const { featureFlags: globalFeatureFlags } = global
  const customerTier = pathOr<CustomerTiers>(CustomerTiers.Freedom, ['customer_tier'], features)
  const plugins = pluginManager.getPlugins()
  const currentPlugin = plugins[currentStack]

  const PluginFrame = currentPlugin.getFrame()

  // const clustersUpdating = useSelector(path([cacheStoreKey, updatingStoreKey, DataKeys.Clusters]))
  // const clusters = useSelector<RootState, CombinedClusterSelector[]>(allClustersSelector)

  // const { loading: loadingUsers } = useListAction(listUsers)
  // const users = useSelector(usersSelector)

  // const loadingClustersArr = useSelector<RootState, boolean[]>(allClustersLoadingPaths)
  // const loadingClusters = loadingClustersArr.some(isTruthy)

  // const shouldBackfillOnboarding = needsOnboardingBackfill(
  //   currentStack,
  //   features,
  //   globalFeatureFlags?.isOnboarded,
  //   clusters,
  //   clustersUpdating,
  //   users,
  // )
  // const isOnboardingTargetEnv = isOnboardingEnv(currentStack, features)

  const isLoadingInitialData = false
  // loadingClusters ||
  // (users?.length === 0 && loadingUsers) ||
  // (globalFeatureFlags === undefined && loadingFeatureFlags) ||
  // (isNilOrEmpty(features) && loadingRegion)

  const showOnboarding = false
  // isOnboardingTargetEnv &&
  // !shouldBackfillOnboarding &&
  // !globalFeatureFlags?.isOnboarded &&
  // pathname !== logoutUrl &&
  // pathname !== helpUrl

  // let onboardingWizardStep = OnboardingStepNames.WelcomeStep
  // if (showOnboarding && clusters?.length >= 1 && users?.length === 1) {
  //   onboardingWizardStep = OnboardingStepNames.InviteCoworkerStep
  // }

  const shouldShowNavbarForCurrentStack =
    currentStack !== AppPlugins.MyAccount ||
    (currentStack === AppPlugins.MyAccount && role === 'admin')

  const showNavBar = shouldShowNavbarForCurrentStack && !loadingRegion
  // (!isOnboardingTargetEnv || (isOnboardingTargetEnv && globalFeatureFlags?.isOnboarded))

  const classes = useStyles({
    path: history.location.pathname,
    showNavBar: showNavBar,
  })

  // useEffect(() => {
  //   async function loadFeatureFlags() {
  //     if (isOnboardingTargetEnv) {
  //       setLoadingFeatureFlags(true)
  //       await updateOnboardingStatus()
  //       setLoadingFeatureFlags(false)
  //     }
  //   }

  //   loadFeatureFlags()
  // }, [isOnboardingTargetEnv])

  // useEffect(() => {
  //   async function loadClusters() {
  //     if (activeRegion) {
  //       await Promise.all([
  //         listClusters.call(),
  //         isDecco(features) ? listImportedClusters.call() : Promise.resolve(),
  //         listCapiClusters.call(),
  //       ])
  //     }
  //   }

  //   loadClusters()
  // }, [activeRegion, features])

  useEffect(() => {
    async function loadBlueprintSettings() {
      if (activeRegion) {
        const blueprints = await getBlueprints()
        const blueprintSettings = blueprints?.[0]
        if (!blueprintSettings) {
          return
        }
        const hostConfigs = await getHostNetworkConfigs()
        const underlayNetwork = getUnderlayInfo(blueprintSettings, hostConfigs)
        dispatch(
          sessionActions.updateSession({
            underlayNetwork,
          }),
        )
      }
    }
    loadBlueprintSettings()
  }, [activeRegion, features])

  useEffect(() => {
    if (showOnboarding) return
    // if (shouldBackfillOnboarding) {
    //   updateGlobalFeatureFlags({ isOnboarded: true }, globalFeatureFlags)
    // }
    if (onboardingRedirectToUrl) {
      history.push(onboardingRedirectToUrl)
      dispatch(sessionActions.updateSession({ onboardingRedirectToUrl: null }))
    }
  }, [
    showOnboarding,
    // shouldBackfillOnboarding,
    globalFeatureFlags,
    onboardingRedirectToUrl,
    history,
  ])

  // Load features once on startup, other times will be handled by region selection
  useEffect(() => {
    const loadRegion = async () => {
      try {
        await loadRegionFeatures()
      } finally {
        dispatch(sessionActions.updateSession({ loadingRegion: false }))
      }
    }
    loadRegion()
  }, [])

  useEffect(() => {
    if (!location) {
      return
    }
    const newStack = determineCurrentStack(location, lastStack)
    setStack(newStack)
    updatePrefs({ lastStack: newStack })
  }, [location])

  useEffect(() => {
    Bugsnag.setUser(userId, name, displayName)

    const cleanupCb = Watchdog.register({
      handler: sessionTimeoutCheck(session, showToast),
      frequency: 1000,
    })
    // Reset the interval if the session changes
    return () => {
      Bugsnag.setUser()
      cleanupCb()
    }
  }, [])

  // Add Zendesk widget script only for Enterprise users
  // useEffect(() => {
  //   if (!airgapped && customerTier === CustomerTiers.Enterprise && isProductionEnv) {
  //     addZendeskWidgetScriptToDomBody({ userId, displayName, email: name })
  //   }
  //   return () => {
  //     hideZendeskWidget()
  //   }
  // }, [userId, displayName, name, customerTier])

  // const renderOnboardingWizard = () => (
  //   <div id="onboarding" className={classes.modal}>
  //     <OnboardingPage initialStep={onboardingWizardStep} />
  //   </div>
  // )

  return (
    <>
      <DocumentMeta title="Welcome" />
      {loadingRegion || isLoadingInitialData ? (
        // (isOnboardingTargetEnv && globalFeatureFlags?.isOnboarded === undefined) ? (
        <Progress loading message={'Loading region data...'} />
      ) : (
        // eslint-disable-next-line no-constant-condition
        <PluginFrame currentPluginId={currentStack} features={features} role={role} />
      )}
    </>
  )
}

export default AuthContainer

/* John: commenting this out for now, the sandbox is not operational currently

{pathStrOr(false, 'experimental.sandbox', features) && (
  <>
    <BannerContainer />
    <BannerContent>
      <div className={classes.sandboxBanner}>
        Welcome! You are in the Platform9 live demo.{' '}
        <Button
          component="a"
          target="_blank"
          href={pmkftSignupLink}
          onClick={() =>
            trackEvent('Live Demo - Signup Request', { 'CTA-Page': 'PMK Live Demo' })
          }
        >
          Start your Free Plan Now
        </Button>{' '}
        or{' '}
        <Button
          component="a"
          target="_blank"
          href={getSandboxUrl('contact')}
          onClick={() => trackEvent('CTA Contact Us', { 'CTA-Page': 'PMK Live Demo' })}
        >
          Contact Us
        </Button>
      </div>
    </BannerContent>
  </>
)} */
