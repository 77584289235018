import { setActiveRegion } from 'api-client/helpers'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { propEq, find, pipe, head, prop, isEmpty } from 'ramda'
import { appUrlRoot } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { useSelector } from 'react-redux'
import useListAction from 'core/hooks/useListAction'
import { listRegions } from 'app/plugins/infrastructure/components/regions/actions'
import { regionsSelector } from 'app/plugins/infrastructure/components/regions/selectors'
import withProgress from 'core/components/progress/withProgress'
import Dropdown from 'core/elements/dropdown'
import { projectAs } from 'utils/fp'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

const currentSectionRegex = new RegExp(`^${appUrlRoot}/[^/]+/?[^/]*`, 'i')

const RegionDropdown = (props) => {
  const { history, location } = useReactRouter()
  const { pathname, hash = '' } = location
  const { prefs, updatePrefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = prefs
  const [loading, setLoading] = useState(false)
  const { loading: loadingRegions, reload: reloadRegions } = useListAction(listRegions)
  const regionsData = useSelector(regionsSelector)
  const regions = useMemo(() => {
    return regionsData.filter((region) => region.id !== 'Infra')
  }, [regionsData])
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { activeRegion } = session

  const curRegionId = useMemo(() => {
    if (currentRegion && find(propEq('id', currentRegion), regions)) {
      return currentRegion
    }
    return pipe(head, prop('id'))(regions)
  }, [regions, currentRegion])

  useEffect(() => {
    // Reload region when changing the current tenant
    if (isEmpty(regions) && loadingRegions === undefined) {
      reloadRegions(true)
    }
  }, [currentTenant, regions])

  const handleRegionSelect = useCallback(
    async (regionId) => {
      if (regionId === curRegionId && regionId === activeRegion) {
        return
      }
      updateRegion(regionId)
    },
    [curRegionId],
  )

  const updateRegion = useCallback(
    async (regionId) => {
      setLoading(true)
      try {
        const [currentSection = appUrlRoot] = currentSectionRegex.exec(pathname + hash) || []
        updatePrefs({ currentRegion: regionId })
        await setActiveRegion(regionId)
        history.push(currentSection)
      } catch (err) {
        console.error(err)
      }
      setLoading(false)
    },
    [regions, pathname, hash, curRegionId],
  )

  const options = useMemo(() => projectAs({ label: 'id', value: 'id' }, regions), [regions])

  return (
    <AsyncDropdown
      name="region"
      label="Region"
      compact={true}
      value={activeRegion}
      onChange={(region) => handleRegionSelect(region)}
      loading={loading || loadingRegions}
      items={options}
    />
  )
}

export default RegionDropdown
